/* eslint-disable no-void */
/**
 * register an async function that doesn't need to be waited for in standard code but will need to be tracked in tests etc
 * @param promiseOrAsyncFunc
 */
export function bg(promiseOrAsyncFunc: Promise<unknown> | (() => Promise<unknown>)): void {
  // TODO
  if (typeof promiseOrAsyncFunc === `function`) {
    void promiseOrAsyncFunc()
  } else {
    void promiseOrAsyncFunc
  }
}
